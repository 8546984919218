
				var addMethods = require("../../node_modules/workerize-loader-wp5/dist/rpc-wrapper.js")
				var methods = ["getInitialBoardForPuzzleFromWorker","getBestMoveForPuzzleFromWorker","getBestMovesForPuzzleFromWorker","getBestMissingMovesForPuzzleFromWorker"]
				module.exports = function() {
					var w = new Worker(__webpack_public_path__ + "built-[1].1ccca9c817e11041efac.worker.js", { name: "built-[1].[contenthash].worker.js" })
					URL.revokeObjectURL(__webpack_public_path__ + "built-[1].1ccca9c817e11041efac.worker.js");
					addMethods(w, methods)
					
					return w
				}
			